import { ListingQuery } from 'graphql/types'
import { TListing as TFooterListing } from 'templates/components/Footer/types'
import { getSimilarUrl } from './listing'

export const getFooterListingObject = (
  listing: ListingQuery['listing'],
): TFooterListing => ({
  city: listing?.address?.city,
  citySlug: listing?.address?.citySlug,
  neighborhood: listing?.address?.neighborhood,
  neighborhoodSlug: listing?.address?.neighborhoodSlug,
  stateSlug: listing?.address?.stateSlug,
  street: listing?.address?.street,
  streetSlug: listing?.address?.streetSlug,
  price: listing?.price,
  area: listing?.area,
})

export const getFooterListingSearchItems = (listing?: TFooterListing) => {
  if (!listing) return []

  const city = listing.city as string
  const citySlug = listing.citySlug as string
  const stateSlug = listing.stateSlug as string
  const neighborhood = listing.neighborhood as string
  const neighborhoodSlug = listing.neighborhoodSlug as string
  const price = listing.price as number
  const street = listing.street as string
  const streetSlug = listing.streetSlug as string

  const address = {
    city,
    citySlug,
    neighborhood,
    neighborhoodSlug,
    stateSlug,
  }

  return [
    {
      label: 'Ver imóveis similares',
      href: getSimilarUrl({ ...listing, address }),
    },
    {
      label: `Ver imóveis no bairro ${neighborhood}`,
      href: `/imoveis/${stateSlug}/${citySlug}/${neighborhoodSlug}/preco-min-${Math.floor(
        price * 0.5,
      )}/preco-max-${Math.floor(price * 1.5)}`,
    },
    {
      label: `Ver imóveis em ${city}`,
      href: `/imoveis/${stateSlug}/${citySlug}/preco-min-${Math.floor(
        price * 0.5,
      )}/preco-max-${Math.floor(price * 1.5)}`,
    },
    {
      label: `Ver imóveis em ${street}`,
      href: `/imoveis/${stateSlug}/${citySlug}/${neighborhoodSlug}/${streetSlug}`,
    },
  ]
}
