type TPlaceSchema = {
  name: string
  lat: number
  lng: number
  addressLocality: string
  addressRegion: string
  streetAddress?: string
}

export const PlaceSchema = (place: TPlaceSchema) => {
  return {
    '@context': 'https://schema.org/',
    '@type': 'Place',
    name: place.name,
    geo: {
      '@type': 'GeoCoordinates',
      latitude: place.lat,
      longitude: place.lng,
    },
    address: {
      '@type': 'PostalAddress',
      addressLocality: place.addressLocality,
      addressRegion: place.addressRegion,
      addressCountry: {
        '@type': 'Country',
        name: 'BR',
      },
      ...(place.streetAddress && { streetAddress: place.streetAddress }),
    },
  }
}
