export const EmCasa = {
  name: 'EmCasa',
  description:
    'A EmCasa te apoia durante todo o processo de compra ou venda de casas e apartamentos, com assessoria gratuita do começo ao fim.',
  telephone: '+55-11-3197-6638',
  email: 'contato@emcasa.com',
  url: 'https://emcasa.com',
  logo: 'https://res.cloudinary.com/emcasa/image/upload/v1609160145/logo/EmCasa-logo.jpg',
  image:
    'https://res.cloudinary.com/emcasa/image/upload/v1609160145/logo/EmCasa-logo.jpg',
}

export const ContactPointSchema = [
  {
    '@type': 'ContactPoint',
    telephone: EmCasa.telephone,
    email: EmCasa.email,
    contactType: 'customer service',
    areaServed: 'BR',
    availableLanguage: 'Portuguese',
  },
]

export const AddressSchema = {
  '@type': 'PostalAddress',
  streetAddress: 'Alameda Santos, 1773',
  addressLocality: 'São Paulo',
  addressRegion: 'SP',
  addressCountry: 'BR',
  postalCode: '01419-002',
}

const SameAsSchema = [
  'https://www.facebook.com/EmCasa',
  'https://www.instagram.com/emcasa',
  'https://www.linkedin.com/company/emcasa',
]

export const OrganizationSchema = {
  '@context': 'http://schema.org',
  '@id': 'https://emcasa.com/#organization',
  '@type': 'Organization',
  ...EmCasa,
  contactPoint: ContactPointSchema,
  address: AddressSchema,
  sameAs: SameAsSchema,
}
