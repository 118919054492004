export const questionsAndAnswers = [
  {
    name: 'O serviço da EmCasa tem custo para o comprador?',
    text: 'Nenhum. Para quem compra, os nossos serviços são totalmente gratuitos desde o começo até o final de todo o processo. Nada sai do seu bolso, nem mesmo o custo da comissão dos nossos especialistas, pois ele é pago pelo proprietário do imóvel.',
  },
  {
    name: 'Como funcionam as etapas de compra?',
    text: 'Depois de encontrar o imóvel ideal, nossos especialistas te apoiam na negociação da proposta, assinatura do contrato preliminar de compra e venda (PCV), na assessoria jurídica (incluindo a ida ao cartório), assinatura final da escritura ou contrato de financiamento. Nesse último caso, a etapa de vistoria também é necessária.',
  },
  {
    name: 'Além do valor do imóvel, o que mais tenho que pagar?',
    text: 'Apenas despesas habituais comuns em uma transação imobiliária, como impostos e taxas cartoriais. Dentre elas: ITBI (Imposto de Transmissão de Bens Imóveis): imposto municipal que varia de acordo com a cidade, podendo chegar até 3% do valor avaliado pela Prefeitura ou do valor da negociação — o que for maior. Escritura: documento que formaliza a transferência do imóvel do vendedor para o comprador. Pode ser pública ou particular (contrato de financiamento). O seu custo varia de estado para estado e de acordo com o valor do imóvel. Registro: Após o pagamento do ITBI e assinatura da escritura, o imóvel precisa ser registrado no cartório. O custo também varia de estado para estado e de acordo com o valor do imóvel.',
  },
  {
    name: 'A EmCasa também aluga imóveis?',
    text: 'Não trabalhamos com locação, apenas com compra e venda de imóveis. Guiamos quem deseja encontrar o imóvel ideal, desde a procura até a entrega das chaves, tudo através do suporte de especialistas que cuidam de toda negociação e assessoria jurídica e financeira.',
  },
  {
    name: 'Posso utilizar meu FGTS?',
    text: 'Sim. É necessário apenas cumprir os pré-requisitos estabelecidos pelo governo.',
  },
  {
    name: 'Tem custo para anunciar na EmCasa?',
    text: 'Não existe nenhum custo para anunciar seu imóvel conosco. Caso o seu imóvel seja vendido pela EmCasa, apenas será cobrada uma taxa de comissão, conforme estabelecido pelo CRECI (Conselho Regional de Fiscalização do profissional Corretor de Imóveis) de cada estado.',
  },
  {
    name: 'Qual tipo de imóvel posso anunciar na EmCasa?',
    text: 'Trabalhamos apenas com imóveis residenciais (casas e apartamentos). Imóveis comerciais ou terrenos, por exemplo, não são anunciados em nosso site.',
  },
  {
    name: 'Meu imóvel atualmente está alugado. Posso anunciar mesmo assim?',
    text: 'Sim. Mas é importante lembrar que os locatários possuem preferência de compra. Então, antes de anunciar, não esqueça de consultar seu locatário e verificar se o mesmo tem interesse em realizar a compra. Caso não haja interesse, você pode anunciar conosco.',
  },
  {
    name: 'Posso anunciar um imóvel para alugar?',
    text: 'Não. A EmCasa não trabalha com locação, apenas com a compra e venda de imóveis residenciais.',
  },
  {
    name: 'Se o imóvel for vendido, quanto preciso pagar de comissão?',
    text: 'O valor da comissão pode variar de acordo com cada estado (a partir de 5%) e conforme o tipo de imóvel que está sendo negociado. É importante saber que nossos especialistas de vendas não dependem de comissão, são contratados e recebem salário fixo, se preocupando somente em entregar de fato o que o cliente precisa.',
  },
  {
    name: 'Como funciona a assessoria de financiamento da EmCasa?',
    text: 'Nossos especialistas negociam as melhores taxas com os principais bancos do mercado, pesquisando e apresentando diversas opções até você encontrar a que mais faz sentido. Além disso, também agilizamos a aprovação do crédito, para que você pegue as chaves do seu imóvel ainda mais rápido.',
  },
  {
    name: 'Como funciona o financiamento imobiliário?',
    text: 'O financiamento imobiliário é dividido em 3 etapas: Aprovação do crédito: O banco analisa a capacidade de pagamento do comprador. Vistoria do imóvel: Um especialista indicado pelo banco verifica se o valor negociado corresponde ao valor real de mercado. Análise jurídica: Toda a documentação do comprador e vendedor são analisadas pelo banco, para que seja feito o contrato de financiamento.',
  },
  {
    name: 'Qual é o valor mínimo para entrada do imóvel?',
    text: 'O valor de entrada deve ser de, no mínimo, 20% do valor total do imóvel. Em nosso site, cada anúncio de imóvel possui um simulador de financiamento, onde você pode ter uma prévia de valores de entrada, preço do imóvel, tempo e parcelas.',
  },
  {
    name: 'Qual é o máximo de parcelas que posso realizar o financiamento?',
    text: 'O prazo máximo para quitar a sua dívida pode variar dependendo da instituição escolhida. Os bancos costumam oferecer um prazo máximo de 420 meses (35 anos), porém, você pode escolher um prazo que forneça uma mensalidade que mais se adeque ao seu orçamento.',
  },
  {
    name: 'Quanto tempo leva para análise de crédito para financiamento junto ao banco?',
    text: 'O prazo em média é de 2 a 3 dias úteis para resposta, mas o tempo de retorno da aprovação de crédito pode variar de acordo com a instituição financeira escolhida.',
  },
]

const faqEntities = questionsAndAnswers.map(({ name, text }) => ({
  '@type': 'Question',
  name,
  acceptedAnswer: {
    '@type': 'Answer',
    text,
  },
}))

export const FAQSchema = {
  '@context': 'https://schema.org',
  '@type': 'FAQPage',
  mainEntity: faqEntities,
}
