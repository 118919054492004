import { FC, PropsWithChildren } from 'react'
import useLoadMapScript from 'hooks/useMapScript'
import classNames from 'classnames'
import { OrganizationSchema } from 'config/schemas'
import Head, { TProps as THeadProps } from '../components/Head'
import Header, { THeaderSharedProps } from '../components/Header'
import { TListing as TFooterListing } from '../components/Footer/types'
import Footer from '../components/Footer'
import { menuNavigation } from '../constants'
import CookieNotification from '../components/CookieNotification'
import styles from './Default.module.scss'
import Navbar from 'templates/components/Navbar'
import { useRouter } from 'next/router'
import { getAppVersion } from 'lib/experiment'

export type TProps = {
  withBottomBar?: boolean
  headerProps?: THeaderSharedProps
  noFooter?: boolean
  noLegalNotification?: boolean
  head: THeadProps
  footerListing?: TFooterListing
  customClass?: {
    main?: string
    footer?: string
  }
  includeMap?: boolean
}

const DefaultTemplate: FC<PropsWithChildren<TProps>> = ({
  children,
  withBottomBar,
  noFooter,
  noLegalNotification,
  head,
  headerProps,
  footerListing,
  customClass,
  includeMap,
}): JSX.Element => {
  const { ScriptComponent } = useLoadMapScript()

  const router = useRouter()

  const isMapRoute = router?.asPath?.includes('/mapa')

  const notRenderNavbarRoutes = [
    'recomendacoes',
    'favoritos',
    '/regioes-atendidas/',
    '/imoveis/imovel',
  ]

  const shouldRenderNavbar =
    router.asPath &&
    !isMapRoute &&
    !notRenderNavbarRoutes.some((substring) =>
      router.pathname.includes(substring),
    )

  const isWebview = !!getAppVersion()

  return (
    <div className={styles.ecDefault}>
      <Head {...head} />
      <script
        type="application/ld+json"
        data-testid="organizationSchemaScript"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(OrganizationSchema),
        }}
      />
      {includeMap && ScriptComponent && <ScriptComponent />}
      {!headerProps?.hide && <Header nav={menuNavigation} {...headerProps} />}
      <div className={styles.ecNavbarContainer}>
        <Navbar shouldRenderNavbar={shouldRenderNavbar} />
      </div>
      <main className={classNames(styles.ecMain, customClass?.main)}>
        {children}
      </main>
      {!noLegalNotification && <CookieNotification />}

      {!noFooter && (
        <Footer
          withBottomBar={withBottomBar}
          listing={footerListing}
          className={classNames(customClass?.footer, {
            [styles.ecExtendedFooter]: shouldRenderNavbar && isWebview,
          })}
        />
      )}
    </div>
  )
}

export default DefaultTemplate
