import { useEffect, useState } from 'react'
import { setCookie, getCookie } from 'cookies-next'
import Notification from 'components/Notification'
import Anchor from 'components/Anchor'
import Typography from 'components/Typography'
import logger from 'lib/logger'
import { getUtmArrayFromUrl } from 'lib/utm'
import { isCookieEnabled } from 'lib/device'
import { getFutureDateFromDays } from 'lib/date'
import { getAppVersion } from 'lib/experiment'

export const legalNotificationCookie = {
  key: 'EmCasaCookieLegal',
  value: 'Done',
}

export const legalNotification = {
  text: 'Usamos cookies para personalizar conteúdos e melhorar a sua experiência. Ao navegar neste site, você concorda com a nossa',
  anchor: 'Política de Cookies.',
  href: '/legal',
  button: {
    label: 'Entendi',
  },
}

const CookieNotification = () => {
  const legalCookie = getCookie(legalNotificationCookie?.key)
  const [isVisible, setIsVisible] = useState(false)
  const isWebview = !!getAppVersion()

  const hideLegalNotification = () => {
    try {
      setCookie(legalNotificationCookie.key, legalNotificationCookie.value, {
        expires: getFutureDateFromDays(365),
      })
    } catch (e) {
      logger.action('site-storage-error', { error: e })
      logger.capture(e)
    }

    setIsVisible(false)
  }

  useEffect(() => {
    const utmArray = getUtmArrayFromUrl(window.location.search)
    if (
      legalCookie !== legalNotificationCookie.value &&
      !utmArray.length &&
      isCookieEnabled()
    ) {
      logger.action('legal-notification-open')
      setIsVisible(true)
    }
  }, [legalCookie])

  if (!isVisible || isWebview) return null

  return (
    <Notification
      visibleTimeout
      desktopAlignment="left"
      buttons={[
        {
          label: legalNotification.button.label,
          props: {
            theme: 'pink',
            onClick: () => {
              logger.action('legal-notification-button')
              hideLegalNotification()
            },
            'aria-label': legalNotification.button.label,
          },
        },
      ]}
    >
      <Typography variant="ParagraphSmall">
        {legalNotification.text}{' '}
        <Anchor href={legalNotification.href} target="_blank">
          {legalNotification.anchor}
        </Anchor>
      </Typography>
    </Notification>
  )
}

export default CookieNotification
