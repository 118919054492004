import { FC } from 'react'
import dynamic from 'next/dynamic'
import Anchor from 'components/Anchor'
import Typography from 'components/Typography'
import useMap from 'hooks/useMap'
import classNames from 'classnames'
import styles from './Footer.module.scss'
import * as events from './events'
import { FOOTER_NAV } from './constants'
import { TListing } from './types'
import About from './components/About'

const EmailModal = dynamic(() => import('./components/EmailModal'))
const PhoneModal = dynamic(() => import('./components/PhoneModal'))
const CreciModal = dynamic(() => import('./components/CreciModal'))

export type TProps = {
  withBottomBar?: boolean
  listing?: TListing
  className?: string
}

const Footer: FC<TProps> = ({ withBottomBar = false, listing, className }) => {
  const [modalMap, { set }] = useMap({
    creciModal: {
      isMounted: false,
      isVisible: false,
    },
    emailModal: {
      isMounted: false,
      isVisible: false,
    },
    phoneModal: {
      isMounted: false,
      isVisible: false,
    },
  })

  const footerNavClasses = classNames(styles.ecFooterNav, {
    [styles.ecFooterNavTwoColumns]: !!listing,
  })

  return (
    <>
      {modalMap.creciModal.isMounted && (
        <CreciModal
          isVisible={modalMap.creciModal.isVisible}
          onClose={() =>
            set('creciModal', { isMounted: true, isVisible: false })
          }
        />
      )}
      {modalMap.emailModal.isMounted && (
        <EmailModal
          isVisible={modalMap.emailModal.isVisible}
          onClose={() =>
            set('emailModal', { isMounted: true, isVisible: false })
          }
        />
      )}
      {modalMap.phoneModal.isMounted && (
        <PhoneModal
          isVisible={modalMap.phoneModal.isVisible}
          onClose={() =>
            set('phoneModal', { isMounted: true, isVisible: false })
          }
        />
      )}

      <footer
        className={classNames(styles.ecFooter, className, {
          [styles.ecFooterWithBar]: withBottomBar,
        })}
      >
        <div className={styles.ecFooterContainer}>
          <div className={footerNavClasses}>
            <h2 className={styles.ecFooterNavTitle}>EmCasa</h2>
            {FOOTER_NAV({
              listing,
              openPhoneModal: () =>
                set('phoneModal', { isMounted: true, isVisible: true }),
              openEmailModal: () =>
                set('emailModal', { isMounted: true, isVisible: true }),
            }).map(
              (category) =>
                !category.hidden && (
                  <div key={category.title} className={styles.ecFooterNavItem}>
                    <Typography
                      variant="TitleSmall"
                      as="h3"
                      bold
                      className={styles.ecFooterNavItemTitle}
                    >
                      {category.title}
                    </Typography>
                    <ul className={styles.ecFooterNavList}>
                      {category.list.map(
                        (item) =>
                          !item.hidden && (
                            <li
                              key={item.label}
                              className={styles.ecFooterNavListItem}
                            >
                              <Anchor
                                element={item.href ? 'a' : 'button'}
                                href={item.href}
                                className={styles.ecFooterNavLink}
                                onClick={() => {
                                  events.footerLinkEvent(
                                    item.href ?? item.label,
                                  )
                                  if (item.onClick) item.onClick()
                                }}
                                target={item.target}
                              >
                                {item.label}
                              </Anchor>
                            </li>
                          ),
                      )}
                    </ul>
                  </div>
                ),
            )}
          </div>
          <About
            onCRECIClick={() =>
              set('creciModal', { isMounted: true, isVisible: true })
            }
          />
        </div>
      </footer>
    </>
  )
}

export default Footer
