import { Listing } from 'graphql/types'

export type TListingSchema = {
  name: string
  schemaDescription: string
  image: string
  url: string
} & Listing

export const ListingSchema = (listing: TListingSchema) => ({
  '@context': 'http://schema.org',
  '@type': [
    'Product',
    'SingleFamilyResidence',
    listing.type === 'Casa' ? 'House' : 'Apartment',
  ],
  identifier: {
    '@type': 'PropertyValue',
    propertyID: 'Listing ID',
    value: listing.id,
  },
  name: listing.name,
  image: listing.image,
  description: listing.schemaDescription,
  address: {
    '@type': 'PostalAddress',
    streetAddress: listing.address?.street,
    addressLocality: listing.address?.neighborhood,
    addressRegion: `${listing.address?.city as string}, ${
      listing.address?.state as string
    }`,
    postalCode: listing.address?.postalCode,
    addressCountry: {
      '@type': 'Country',
      name: 'BR',
    },
  },
  offers: {
    '@type': 'Offer',
    priceCurrency: 'BRL',
    price: listing.price,
    url: listing.url,
    availability: listing.isActive
      ? 'http://schema.org/InStock'
      : 'http://schema.org/Discontinued',
  },
  geo: {
    '@type': 'GeoCoordinates',
    latitude: listing.address?.lat,
    longitude: listing.address?.lng,
  },
  numberOfRooms: listing.rooms,
  numberOfBathroomsTotal: listing.bathrooms,
  floorLevel: listing.floor,
  floorSize: listing.area,
  accommodationCategory: 'RESO',
})
