import { useState } from 'react'
import Script from 'next/script'
import { GOOGLE_MAPS_API_KEY } from 'config'

declare global {
  interface Window {
    onMapLoaded: () => void
  }
}

const useLoadMapScript = () => {
  const [isScriptLoaded, setIsScriptLoaded] = useState(false)
  if (typeof window === 'undefined') return { isMapScriptLoaded: false }

  window.onMapLoaded = () => {
    setIsScriptLoaded(true)
  }
  const loadedLibrary = !!window.google

  const ScriptComponent = () => (
    <Script
      id="googleMapsPlaces"
      strategy="lazyOnload"
      src={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places&region=BR&language=pt-br&callback=onMapLoaded`}
    />
  )

  return {
    isMapScriptLoaded: isScriptLoaded || loadedLibrary,
    ScriptComponent,
  }
}

export default useLoadMapScript
