import React, { FC } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useAtom } from 'jotai'
import { TIconComponent } from 'icons/types'
import { IconHeart, IconRealEstate, IconSearch, IconStrip3 } from 'icons'
import Button from 'components/Button'
import styles from './Navbar.module.scss'
import { menuAtom } from '../Menu/menuAtom'
import { onClickNavbarButton } from './events'
import classNames from 'classnames'
import { getAppVersion } from 'lib/experiment'

interface NavbarButtonProps {
  Icon: Omit<TIconComponent, 'width' | 'height' | 'color'>
  children: React.ReactNode
  href?: string
  onClick?: () => void
  isActive?: boolean
}

interface IProps {
  shouldRenderNavbar: boolean | ''
}

const isWebview = !!getAppVersion()
const NavbarButton: FC<NavbarButtonProps> = ({
  Icon,
  children,
  href,
  onClick,
  isActive,
}) => {
  return href ? (
    <Link href={href} passHref>
      <Button
        role="button"
        className={classNames(styles.ecNavbarButton, {
          [styles.ecNavbarButtonActive]: isActive,
        })}
        Icon={{
          Component: Icon.Component,
          color: 'white',
          title: Icon.title,
        }}
        color="white"
        onClick={onClick}
      >
        {children}
      </Button>
    </Link>
  ) : (
    <Button
      aria-label="Abrir menu"
      role="button"
      className={styles.ecNavbarButton}
      Icon={{
        Component: Icon.Component,
        color: 'white',
        title: Icon.title,
      }}
      color="white"
      onClick={onClick}
    >
      {children}
    </Button>
  )
}

const Navbar: FC<IProps> = ({ shouldRenderNavbar }) => {
  const [{ isVisible }, setMenu] = useAtom(menuAtom)
  const router = useRouter()
  const currentPath = router.pathname

  if (!isWebview || !shouldRenderNavbar) {
    return null
  }

  const buttons = [
    {
      Icon: { Component: IconSearch, title: 'Busca' },
      text: 'Busca',
      href: '/imoveis',
      onClick: () => onClickNavbarButton('search'),
      isActive: currentPath === '/imoveis',
    },
    {
      Icon: { Component: IconHeart, title: 'Coração' },
      text: 'Favoritos',
      href: '/favoritos',
      onClick: () => onClickNavbarButton('favorites'),
      isActive: currentPath === '/favoritos',
    },
    {
      Icon: { Component: IconRealEstate, title: 'Recomendação' },
      text: 'Recomendação',
      href: '/recomendacoes',
      onClick: () => onClickNavbarButton('recommendation'),
      isActive: currentPath === '/recomendacoes',
    },
    {
      Icon: { Component: IconStrip3, title: 'Menu' },
      text: 'Menu',
      onClick: () => {
        setMenu({ isVisible: true, isMounted: true })
        onClickNavbarButton('menu')
      },
      isActive: isVisible,
    },
  ]

  return (
    <>
      <div className={styles.ecNavbarContainer}>
        {buttons.map((button, index) => (
          <NavbarButton
            href={button.href}
            key={index}
            {...button}
            onClick={button.onClick}
          >
            {button.text}
          </NavbarButton>
        ))}
      </div>
    </>
  )
}

export default Navbar
